      <div class="mainContainerConfig">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center pt-4">
   
                <img  
                src="https://gustosome.com/appLogo400x130.webp"
                srcset="https://gustosome.com/appLogo300x86.webp 300w,
                https://gustosome.com/appLogo400x130.webp 400w, 
                https://gustosome.com/logo-452x130_1.webp 452w"
                sizes="(max-width: 760px) 300px,(min-width: 760px) 452px"
                alt="Gustosome">
    
    
            </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="pt-4" >
            
                <h1 class="extraBold text-center text-white"> 
                Spice up! 
                </h1>  
                <h5 class="extraBold text-center text-white mb-0">Keto Friendly!</h5>
                <h5 class="extraBold text-center text-white">Sugar Free!</h5>
                </div>
                <div class="d-flex justify-content-center">
                  <a href="https://instagram.com/gustosome?igshid=YmMyMTA2M2Y=" target="_blank"><i style="color: white" class="fa-brands fa-instagram socialMediaIcon paddingRightSocialMediaIcon"></i></a>
                  
                  <a href=" https://www.tiktok.com/@gustosome" target="_blank"> <i style="color: white" class="fa-brands fa-tiktok socialMediaIcon"></i></a>
              </div>
            </div>
          </div>
        </div>
  
         


<div class="container-fluid noOverFlow-x px-0 pt-3">


 <div class="row d-flex gx-2 gy-4 pb-4">
  <div class="col-xl-12">
    <div class="row d-flex justify-content-center pb-4">
      <div class="col-xl-8">
          <div class="itemTitle">
          <h4 class=" text-center appYellow text-center extraBold mb-0">            
           Homemade Hot Salsa

          </h4>
          </div>
      </div>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-12 mb-3">
        <div class="image-sauce-container d-flex justify-content-center">
          <div class="d-flex justify-content-center whiteBoxShadow">
            <img  

            class="rounded img-thumbnail"
            src="https://gustosome.com/greenSauceSm402x302.webp"
            srcset="https://gustosome.com/redThaiPeno650x488.webp 650w,
            https://gustosome.com/redThaiPenoLg579x434.webp 579w,
            https://gustosome.com/redThaiPenoSm402x302.webp 402w"
            sizes="(max-width: 760px) 650px,(min-width: 760px) 650px"
            alt="Gustosome">
         
          </div>
          <button [routerLink]="['selection/homemadesauce/recipe-list']"   class="SauceBtn btnBoxShadow">See More</button>


        </div>
      </div>
    </div>


  </div>


</div>








<div class="row d-flex justify-content-center gx-2 gy-4 pb-4">

<div class="col-xl-12">
  <div class="row d-flex justify-content-center pb-4">
    <div class="col-xl-8">
        <div class="itemTitle">
        <h4 class=" text-center appYellow text-center extraBold mb-0">            
         Pepper Plants

        </h4>
        </div>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="col-lg-12 mb-3">
      <div class="image-sauce-container d-flex justify-content-center">
        <div class="d-flex justify-content-center whiteBoxShadow">
          <img  

          class="rounded img-thumbnail"
          src="https://gustosome.com/plants/img/redthai/8weeks/RedThaiPlantCover650x488EightWks.webp"

          sizes="(max-width: 760px) 650px,(min-width: 760px) 650px"
          alt="Gustosome">
       
        </div>
        <button [routerLink]="['selection/plants/plants-lst']"   class="SauceBtn btnBoxShadow">See More</button>


      </div>
    </div>
  </div>


</div>
</div>







  <!-- <div class="row d-flex justify-content-center gx-2 gy-4 pb-4">
    <div class="col-xl-12">
      <div class="row  d-flex  justify-content-center ">
        <div class="col-xl-8">
          <div class="itemTitle">
            <h4 class="text-center appYellow extraBold mb-0">Dan-O's Seasoning </h4>

            </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 d-flex flex-column justify-content-center">
          <div >
              <app-testimonials [reviewToDisplay]="this.review_to_display"></app-testimonials>

          </div>
        </div>
      </div>


      <div class="row d-flex justify-content-center">
        <div class="col-lg-8">
          <div class="image-seasoning-container d-flex justify-content-center">
            <div class="d-flex justify-content-center whiteBoxShadow">
             
              <video width="650" height="650" muted autoplay loop class="rounded img-thumbnail" 
              poster="https://gustosome.com/seasoningGifPoster.webp"  >
         
                <source src="https://gustosome.com/danOanimated.ogv" type="video/ogv"> 
                <source src="https://gustosome.com/danOanimated.webm" type="video/webm"> 
                <source src="https://gustosome.com/danOanimated.ogg" type="video/ogg">
                <source src="https://gustosome.com/danOanimated.mp4" type="video/mp4">
    
                Your browser does not support the video tag.
              </video>
            </div>
    
           
            <button [routerLink]="['selection/seasoning']"  class="SauceBtn btnBoxShadow">
              <strong>See More</strong>
            </button>
          </div>
        </div>
      </div>

    </div>
 
  </div> -->


</div>
  </div>















    

    